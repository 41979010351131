import React, { useState, useEffect, useMemo } from 'react'
import ISearchFiltersProps, {
  ECheckboxTypes,
  IAccordionProps,
  ICheckboxProps,
} from './interface'
import blockWrapperProps from '../../../Layout/BlockWrapper/props'
import ButtonContainer from '../../../Block/ButtonContainer/ButtonContainer'
import IButtonsContainerProps from '../../../Block/ButtonContainer/interface'
import classNames from 'classnames'
import { defaults } from '../../Utils/utils'

const FiltersMenu = (props: ISearchFiltersProps) => {
  const {
    facets,
    openFacets = [],
    openFacetsCallBack,
    sortItems,
    buttonLabel = 'filters',
    callBack,
    clearFlag = defaults.clearFlag,
  } = props

  /**
   * This generates the default filters and sorting object to turn back on "Clear Filters"
   * @param json The JSON blob coming from BE that holds all the data
   * @returns Object
   */
  const generateDefaultFilters = (
    facets: ISearchFiltersProps['facets'],
    sortItems: ISearchFiltersProps['sortItems']
  ) => {
    const headlines = facets.map((facet) => {
      const tempSelectedFilters = facet.filters.filter(
        (item) => item.selected === true
      )
      return [facet.value, tempSelectedFilters.map((filter) => filter.value)]
    })

    if (sortItems?.length) {
      let selectedIndex = 0
      for (let i = 0; i < sortItems.length; i += 1) {
        selectedIndex = sortItems[i].selected ? i : selectedIndex
      }
      headlines.push(['sortValue', sortItems[selectedIndex].value])
    }

    return Object.fromEntries(headlines)
  }

  /**
   * This initializes the data for the filters and sort items in the filters menu
   * @param defaults Array
   * @param sortItems Array
   * @returns Object
   */
  const initializeFilters = (
    defaults: typeof defaultFiltersData,
    sortItems: ISearchFiltersProps['sortItems']
  ) => {
    return {
      ...defaults,
      sortBy: sortItems ? sortItems[0].sortType : undefined,
    }
  }

  /**
   *
   * @param json The JSON blob coming from BE that holds all the data
   * @returns Object:
   * example: {
   *    accordionPanel1: 'filterValueSelectAll',
   *    accordionPanel2: 'filterValueSelectAll',
   * }
   */
  const generateUnSelectAlls = (facets: ISearchFiltersProps['facets']) => {
    const unSelectAlls = facets.map((facet) => {
      return [
        facet.value,
        facet.filters.filter((item) => item.unSelectAll === true)[0]?.value ||
          '',
      ]
    })
    return Object.fromEntries(unSelectAlls)
  }

  const defaultFiltersData = generateDefaultFilters(facets, sortItems)
  const unSelectAlls = generateUnSelectAlls(facets)
  const [filtersVisible, setFiltersVisible] = useState(false)
  const [filtersData, setFiltersData] = useState(
    initializeFilters(defaultFiltersData, sortItems)
  )
  const [internalClearFlag, setInternalClearFlag] = useState(clearFlag)

  /**
   * This returns the number of filters checked
   */
  const countSelectedItems = useMemo(() => {
    let count = 0
    for (const key in filtersData) {
      if (
        Object.prototype.hasOwnProperty.call(filtersData, key) &&
        Array.isArray(filtersData[key])
      ) {
        count += filtersData[key].filter(
          (item: string) => typeof item === 'string' && item?.length
        ).length
      }
    }

    return count
  }, [filtersData])

  const showFiltersMenu = (flag: boolean) => {
    setFiltersVisible(flag)
  }

  const clearFilters = (filter: string) => {
    if (filter === defaults.clearFlag.filter) {
      setFiltersData(defaultFiltersData)
      callBack([])
    } else {
      const updatedFilters: {} | undefined = {}
      const payloadFilters: string[] | undefined = []
      for (const key in filtersData) {
        if (
          Object.prototype.hasOwnProperty.call(filtersData, key) &&
          Array.isArray(filtersData[key])
        ) {
          updatedFilters[key] = filtersData[key].filter(
            (item: string) => typeof item === 'string' && item !== filter
          )
          payloadFilters.push(...updatedFilters[key])
        }
      }
      openFacetsCallBack(undefined)
      callBack(payloadFilters)
      setFiltersData(updatedFilters)
    }
    showFiltersMenu(false)
  }

  const applyFilters = () => {
    const selectedFilters: any[] | undefined = []
    for (const key in filtersData) {
      if (
        Object.prototype.hasOwnProperty.call(filtersData, key) &&
        Array.isArray(filtersData[key])
      ) {
        selectedFilters.push(
          filtersData[key].filter(
            (item: string) => typeof item === 'string' && item?.length
          )
        )
      }
    }

    if (filtersData.sortBy) {
      callBack(
        selectedFilters.flat(),
        filtersData.sortBy,
        filtersData.sortValue
      )
    } else {
      callBack(selectedFilters.flat())
    }
    showFiltersMenu(false)
  }

  const buttonsContainerProps: IButtonsContainerProps = {
    ...blockWrapperProps,
    name: 'buttonContainer',
    layout: 'left',
  }

  const FEATURE_NAMES = {
    BUTTON: 'filtersCTA',
    MENU: 'filtersMenu',
  }

  const radioInputClasses = (flag: boolean) => {
    return classNames('radio-round--default', {
      'radio-round--disabled radio-round--no-cursor': flag,
    })
  }

  const Checkbox = (props: ICheckboxProps) => {
    const {
      name,
      value,
      sortType,
      unSelectAll,
      type,
      required = false,
      disabled = false,
      group,
      tabIndex = 0,
    } = props

    const inputId = `checkbox-${group}-${value}`
    const addRemoveValue = (value: string, addValue: boolean, data: string) => {
      if (addValue && !filtersData[data].includes(value)) {
        if (unSelectAll) {
          return [value]
        } else {
          filtersData[data].push(value)
          return filtersData[data].filter(
            (item: string) => item !== unSelectAlls[group]
          )
        }
      }
      if (!addValue) {
        if (!unSelectAll) {
          const tempFilters = filtersData[data].filter(
            (item: string) => item !== value
          )

          if (tempFilters && tempFilters.length !== 0) {
            return tempFilters
          }
          return [unSelectAlls[group]]
        }
        return filtersData[data]
      }
    }

    return (
      <div key={value}>
        <label className='input__labelText' htmlFor={inputId}>
          <input
            id={inputId}
            type={type}
            name={type === ECheckboxTypes.CHECKBOX ? value : sortType}
            value={value}
            required={required}
            className={classNames(
              `radio-round ${radioInputClasses(disabled)} input__${type}`
            )}
            checked={
              type === ECheckboxTypes.CHECKBOX
                ? filtersData[group].includes(value)
                : filtersData[group] === value
            }
            disabled={disabled}
            onChange={(event: React.ChangeEvent) => {
              setFiltersData({
                ...filtersData,
                [group]:
                  type === ECheckboxTypes.CHECKBOX
                    ? addRemoveValue(
                        value,
                        (event.target as HTMLInputElement).checked,
                        group
                      )
                    : value,
              })
            }}
            tabIndex={tabIndex}
          />
          <div
            className='input__labelText--checkbox'
            onClick={(e) =>
              (
                e.currentTarget.parentElement?.firstChild as HTMLInputElement
              ).click()
            }>
            {name}
          </div>
        </label>
      </div>
    )
  }

  const Accordion = (props: IAccordionProps) => {
    // This is base on the Accordion component and uses the original styling. BA 2024-07-01
    const { blocks } = props
    const expandedClass = 'accordion__block--expanded'

    const openCloseItem = (e: React.MouseEvent) => {
      let newStatus = 'false'
      let modifierClass = ''
      let maxHeight = 0
      let tabIndex = -1
      const btn = e.currentTarget
      const itemParent: HTMLElement = btn.parentElement as HTMLElement
      const itemContent = btn.nextElementSibling as HTMLElement
      const isOpen = btn.ariaExpanded
      const controls = itemContent.querySelectorAll(
        '.radio-round'
      ) as NodeListOf<HTMLInputElement>

      if (isOpen === 'false') {
        newStatus = 'true'
        modifierClass = expandedClass
        maxHeight = itemContent.scrollHeight
        tabIndex = 0
      }
      itemParent.dataset.expanded = newStatus
      itemParent.classList.remove(expandedClass)
      if (modifierClass) {
        itemParent.classList.add(modifierClass)
      } else {
        // If this item is closed, remove facet from openFacets list
        const newOpenFacets = openFacets.filter(
          (openFacet) => openFacet !== itemParent.id
        )
        openFacetsCallBack(newOpenFacets)
      }
      btn.ariaExpanded = newStatus
      itemContent.ariaHidden = newStatus
      itemContent.style.maxHeight = `${maxHeight}px`
      controls.forEach((control) => {
        control.tabIndex = tabIndex
      })
    }

    return (
      <ul>
        {blocks.map(({ body, expanded, headline, id }) => (
          <li
            key={id}
            id={id}
            className={`accordion__block accordion__block--transition ${
              expanded ? expandedClass : ''
            }`}
            data-expanded={expanded}>
            <button
              aria-controls={`${id}-accordion-${headline
                .trim()
                .split(' ')
                .join('-')}`}
              aria-expanded={expanded}
              className='accordion__btn'
              onClick={(e) => openCloseItem(e)}>
              <span className='accordion__headline'>{headline}</span>
              {body && (
                <i className='accordion__arrow' aria-hidden='true'>
                  arrow_forward_ios
                </i>
              )}
            </button>

            <div
              aria-hidden={!expanded}
              style={!expanded ? { maxHeight: 0 } : { maxHeight: 'none' }}
              className='accordion__content'
              id={`${id}-accordion-${headline.trim().replace(/\s+/g, '-')}`}
              onClick={() => {
                openFacets?.push(id)
                const newOpenFacets = [...new Set([...openFacets])]
                openFacetsCallBack(newOpenFacets)
              }}>
              {body}
            </div>
          </li>
        ))}
      </ul>
    )
  }

  const generateAccordionContent = (
    filters: ISearchFiltersProps['facets'],
    sortItems: ISearchFiltersProps['sortItems']
  ) => {
    const myFilters = filters.map((filter) => {
      const isExpanded =
        openFacets?.includes(filter.value) ||
        filter.filters.filter((filter) => filter.selected).length > 0
      return {
        body: filter.filters.map((item: any) => {
          return (
            <Checkbox
              key={`${item.value}-${item.filter}`}
              {...item}
              type={ECheckboxTypes.CHECKBOX}
              selected={defaultFiltersData[filter.value].includes(item.filter)}
              group={filter.value}
              tabIndex={!isExpanded ? -1 : 0}
            />
          )
        }),
        // Opens every panel that has at least one tick on load
        expanded: isExpanded,
        headline: filter.name,
        id: filter.value,
      }
    })

    const mySorters = {
      body: sortItems?.map((item: any) => (
        <Checkbox
          key={`${item.value}-${item.filter || item.name}`}
          {...item}
          type={ECheckboxTypes.RADIO}
          group='sortValue'
          tabIndex={-1}
        />
      )),
      expanded: false,
      headline: 'Sort by',
      id: 'sortBy',
    }

    return sortItems?.length ? [...myFilters, mySorters] : myFilters
  }

  const accordionProps = {
    blocks: generateAccordionContent(facets, sortItems),
  }

  useEffect(() => {
    if (JSON.stringify(internalClearFlag) !== JSON.stringify(clearFlag)) {
      setInternalClearFlag(clearFlag)
    }
  }, [clearFlag])

  useEffect(() => {
    const { filter, status } = internalClearFlag
    if (status) {
      clearFilters(filter)
      setInternalClearFlag(defaults.clearFlag)
    }
  }, [internalClearFlag])

  return (
    <div className='search-filters'>
      <div className='search-filters__button-container'>
        <button
          // if no filters, disable the button
          disabled={accordionProps.blocks.length === 0}
          id={FEATURE_NAMES.BUTTON}
          aria-haspopup='true'
          aria-expanded={filtersVisible}
          aria-controls={FEATURE_NAMES.MENU}
          aria-pressed={filtersVisible}
          className={classNames(
            'button link__color--primary button__type--secondary button__size--base search-filters__toggle-button',
            {
              'search-filters__toggle-button--open': filtersVisible,
            }
          )}
          onClick={() => showFiltersMenu(!filtersVisible)}>
          <div className='search-filters__icon-wrapper'>
            <i className='icon__md' aria-hidden='true'>
              tune
            </i>
            <span>{buttonLabel}</span>
          </div>
          {countSelectedItems ? (
            <span className='search-filters__selected-count'>
              {countSelectedItems}
            </span>
          ) : null}
        </button>
      </div>
      <div
        className={classNames('search-filters__menu', {
          'search-filters__menu--visible': filtersVisible,
        })}
        aria-modal={true}
        role='dialog'
        id={FEATURE_NAMES.MENU}
        aria-labelledby={FEATURE_NAMES.BUTTON}>
        <Accordion {...accordionProps} />
        <ButtonContainer {...buttonsContainerProps}>
          <button
            className='button link__color--primary button__type--secondary button__size--base'
            onClick={() =>
              setInternalClearFlag({
                ...defaults.clearFlag,
                status: true,
              })
            }>
            Clear all filters
          </button>
          <button
            className='button button__color--primary button__type--primary button__size--base'
            onClick={() => applyFilters()}>
            Apply filters
          </button>
        </ButtonContainer>
      </div>
    </div>
  )
}

export default FiltersMenu
