import React, { useEffect, useState } from 'react'
import AutoCompletes, { getAutoCompletes } from '../AutoCompletes/AutoCompletes'

import { useDebounce } from '../../Utils/utils'
import IProps from './interface'
import { IAutoCompleteItem } from '../../Element/AutoCompletes/interface'

const SearchInput = (props: IProps) => {
  const {
    baseURL = '',
    searchTerm,
    currentContentType = null,
    placeholderText,
    showSubmitButtons = true,
    suggestions,
    callBack,
  } = props

  const [searchInput, setSearchInput] = useState(searchTerm || '')
  const [autoCompletes, setAutoCompletes] = useState<
    { items: IAutoCompleteItem[]; suggestions: string[] } | undefined
  >()
  const [inputFocus, setInputFocus] = useState<boolean>(false)
  const debouncedSearchTerm = useDebounce(searchInput, 700)

  /**
   * Clears the search input
   */
  const clearInput = () => {
    setSearchInput('')
  }

  /**
   * Validates the form and calls for proceeding to the search with the search input value
   * @param event Event
   */
  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault()
    if (searchInput && searchInput.trim() !== '') {
      const submits = document.querySelectorAll(
        '.searchInput__input-search-button, .searchInput__mobile-search-button'
      )
      const focusableSubmit = Array.from(submits).filter(
        (button) => (button as HTMLElement).offsetHeight !== 0
      )
      if (focusableSubmit.length > 0) {
        ;(focusableSubmit[0] as HTMLButtonElement).focus()
      } else {
        const searchField = document.querySelector(
          '.searchInput__input'
        ) as HTMLInputElement
        if (searchField) {
          searchField.blur()
        }
      }
      callBack(searchInput.trim())
    }
  }

  useEffect(() => {
    if (debouncedSearchTerm && inputFocus) {
      // Query API and populate the auto completes array
      getAutoCompletes({
        baseURL,
        searchTerm: debouncedSearchTerm,
        contentType: currentContentType,
        callBack: setAutoCompletes,
      })
    }
  }, [debouncedSearchTerm])

  /**
   * Clears the autocomplete array when the search input doesn't have the focus
   */
  useEffect(() => {
    if (!inputFocus) {
      setAutoCompletes(undefined)
    }
  }, [inputFocus, searchInput, currentContentType])

  /**
   * Updates the search term with what's in the search input
   */
  useEffect(() => {
    if (searchTerm) {
      setSearchInput(searchTerm || '')
    }
  }, [searchTerm])

  return (
    <div className='searchInput'>
      <div className={`searchInput__inner`}>
        <form
          role='search'
          aria-label='Search'
          onSubmit={handleSubmit}
          className='searchInput__form'>
          <label htmlFor='search' className='sr-only' id='search-label'>
            Search:
          </label>
          <input
            className={'searchInput__input'}
            aria-labelledby='search-label'
            id='search'
            type='text'
            value={searchInput}
            onChange={(e) => {
              setSearchInput(e.target.value)
            }}
            onFocus={() => setInputFocus(true)}
            onBlur={() => {
              setTimeout(() => {
                if (
                  !document.activeElement!.classList[0].includes(
                    'autocompletes__link'
                  )
                ) {
                  setInputFocus(false)
                }
              }, 1)
            }}
            placeholder={placeholderText}
            autoComplete='off'
          />
          <div className='searchInput__search-buttons'>
            {searchInput?.length ? (
              <button
                className='button button__color--tertiary button__type--secondary button__size--base button__icon searchInput__input-clear-button'
                aria-label='Clear search input'
                type='button'
                onClick={clearInput}>
                <i aria-hidden='true'>close</i>
              </button>
            ) : null}
            {showSubmitButtons && (
              <button
                className='button button__color--primary button__type--primary button__size--base button__icon searchInput__input-search-button'
                aria-label='Search'
                type='submit'>
                <i aria-hidden='true'>search</i>
              </button>
            )}
          </div>
          {showSubmitButtons && (
            <button
              className='button button__color--primary button__type--primary button__size--base button__fullWidth searchInput__mobile-search-button'
              type='submit'>
              Search <i aria-hidden='true'>search</i>
            </button>
          )}
        </form>
        {searchInput && (autoCompletes?.items || autoCompletes?.suggestions) ? (
          <AutoCompletes
            items={autoCompletes.items}
            suggestions={autoCompletes.suggestions}
            searchTerm={searchInput}
            suggestionsCallBack={setSearchInput}
          />
        ) : null}
        {suggestions?.map((suggestion) => {
          if (suggestion?.value && suggestion.name) {
            return (
              <div
                key={suggestion.name}
                className='searchInput__suggestion-wrapper'>
                <span>{suggestion.name}</span>
                &apos;
                <button
                  className='searchInput__suggestion-button'
                  onClick={(event) => {
                    setSearchInput(suggestion.value)
                    handleSubmit(event)
                  }}>
                  {suggestion.value}
                </button>
                &apos;
              </div>
            )
          }
          return null
        })}
      </div>
    </div>
  )
}

export default SearchInput
