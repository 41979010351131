import React from 'react'
import classnames from 'classnames'

import IProps from './interface'
import BlockWrapper from '../../Layout/BlockWrapper/BlockWrapper'

const ButtonContainerInner = (props: Pick<IProps, 'layout' | 'children' | 'verticalAlign'>) => {
  const {
    layout = 'left',
    verticalAlign = 'middle',
    children,
  } = props
  return (
    <div
      className={classnames(
        `buttonContainer__layout`,
        {[`buttonContainer__layout--${layout}`]: layout},
        {[`buttonContainer__layout--${verticalAlign}`]: verticalAlign},
      )}
    >
      {children}
    </div>
  )
}

export { ButtonContainerInner as ButtonContainerWidget }

const ButtonContainer = (props: IProps) => {
  const { layout, children, verticalAlign, ...rest } = props

  return (
    <BlockWrapper {...rest}>
      <ButtonContainerInner
        layout={layout}
        verticalAlign={verticalAlign}
      >{children}</ButtonContainerInner>
    </BlockWrapper>
  )
}

export default ButtonContainer
