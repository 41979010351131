import React from 'react'
import classNames from 'classnames'

import IProps, { ISrcSet } from './interface'

const getImageSrc = (srcset: ISrcSet | string) => {
  if(Array.isArray(srcset)) {
    const lastItem = srcset[srcset.length - 1]
    return lastItem.url.split(' ')[1] || lastItem.url
  }
  return srcset
}
const Picture = (props: IProps) => {
  const { srcset, classes, role, alt, loading, cover } = props

  if(!srcset) return null
  return (
    <picture {...(classes?.picture ? { className: classes.picture } : {})}>
      {Array.isArray(srcset) && srcset.map((src) => (
        <source
          key={src.url}
          srcSet={src.url}
          media={`(max-width: ${src.breakpoint}px)`}
        />
      ))}
      <img
        className={classNames(
          {
            'picture__img--cover': cover,
          },
          classes?.img ?? ''
        )}
        src={getImageSrc(srcset)}
        alt={alt ?? ''}
        {...(role ? { role: role } : {})}
        {...(loading ? { loading: loading } : {})}
      />
    </picture>
  )
}

export default Picture
