import React from 'react'

import IProps from './interface'
import Picture from '../../../Element/Picture/Picture'
import SearchInput from '../SearchInput/SearchInput'

const CourseHeader = (props: IProps) => {
  const {
    baseURL = '',
    title,
    introText,
    quickLinks,
    backgroundImage,
    searchTerm,
    callBack,
  } = props

  return (
    <div className='courseHeader'>
      <div className='courseHeader__bg-image-wrapper'>
        {backgroundImage?.crops?.length ? (
          <Picture
            srcset={backgroundImage.crops}
            alt={backgroundImage.alt}
            loading={'eager'}
            classes={{
              picture: 'courseHeader__picture',
              img: 'courseHeader__bg-image',
            }}
          />
        ) : null}
      </div>
      <div className={`courseHeader__inner`}>
        <div className='courseHeader__content'>
          <div className='courseHeader__title-wrapper'>
            <h1 className='courseHeader__title'>{title}</h1>
            <p className='courseHeader__intro-text'>{introText}</p>
          </div>
          <div className='courseHeader__input-wrapper'>
            <SearchInput
              baseURL={baseURL}
              searchTerm={searchTerm}
              currentContentType={''}
              placeholderText={'Enter course name, course code or area of interest'}
              showSubmitButtons={true}
              callBack={callBack}
            />
          </div>
          {quickLinks?.length ? (
            <div className='courseHeader__quick-links'>
              <h2 className='courseHeader__quick-links-label'>
                information for
              </h2>
              <ul className='courseHeader__quick-links-list'>
                {quickLinks.map((el) => (
                  <li key={el.text}>
                    <a
                      href={el.href}
                      target={el.target}
                      className='courseHeader__link'>
                      <span className='courseHeader__link-item'>
                        <i
                          className='courseHeader__link-icon'
                          aria-hidden='true'>
                          arrow_right_alt
                        </i>
                        <span className='courseHeader__link-text'>
                          {el.text}
                        </span>
                      </span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default CourseHeader
