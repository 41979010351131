import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import IProps from './interface'

const CustomSelect = (props: IProps) => {
  const {
    label,
    prefix = '',
    optionsLabel = 'options list',
    callBack,
    options,
  } = props
  const initialValue = options.filter((option) => option.selected)
  const [currentValue, setCurrentValue] = useState(
    initialValue[0] || options[0]
  )
  const [showDropdown, setShowDropdown] = useState(false)

  /**
   * This sends the selected value and closes the drop down when the value is changed and the drop down is open
   */
  useEffect(() => {
    if (showDropdown) {
      const { sortType, sortValue } = currentValue
      setShowDropdown(false)
      callBack({
        sortType,
        sortValue,
      })
    }
  }, [currentValue])

  return (
    <div className='custom-select'>
      <div className={`custom-select__inner`}>
        <button
          className={classNames('custom-select__button', {
            'custom-select__button--open': showDropdown,
          })}
          onClick={() => setShowDropdown(!showDropdown)}
          aria-haspopup='true'
          aria-expanded={showDropdown}
          aria-controls='custom-selectMenu'
          aria-label={label}>
          <span>{`${prefix} ${currentValue.name}`}</span>
          <i className='custom-select__arrow' aria-hidden='true'>
            arrow_forward_ios
          </i>
        </button>
        <ul
          id={`custom-selectMenu-${label}`}
          className={classNames('custom-select__options-list', {
            'custom-select__options-list--hidden': !showDropdown,
          })}
          aria-hidden={!showDropdown}
          aria-label={optionsLabel}
          role='menu'>
          {options.map((option, index) => (
            <li
              role='presentation'
              tabIndex={-1}
              key={`${index}-${option.sortValue}`}
              className={classNames('custom-select__list-item', {
                'custom-select__list-item--selected':
                  option.sortValue === currentValue.sortValue,
              })}>
              <button
                role='menuitem'
                aria-label={option.name}
                tabIndex={0}
                className='custom-select__select-button'
                onClick={() => setCurrentValue(option)}>
                <span className='custom-select__button-name'>
                  {option.name}
                </span>
                {option.sortValue === currentValue.sortValue && (
                  <span className='custom-select__selected-check'>
                    <i aria-hidden='true'>
                      check
                    </i>
                  </span>
                )}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default CustomSelect
