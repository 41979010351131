import React, { useEffect } from 'react'
import classNames from 'classnames'
import IResultCard, { ICodedProps } from './interface'
import Picture from '../../../Element/Picture/Picture'
import Title from '../../../Element/Title/Title'
import Tag from '../../../Element/Tag/Tag'

const ResultCard = (props: IResultCard) => {
  const {
    uid,
    title,
    blurb,
    url,
    contentType,
    image,
    duration,
    applications,
    price,
    status,
    featured,
    location,
    positions,
    faculties,
    studyLevels,
    selectionRanks,
    intakes,
  } = props
  const contentTypes = {
    course: 'Course',
    event: 'Event',
    news: 'News',
    people: 'People',
    scholarship: 'Scholarship',
    story: 'Story',
    webpage: 'Webpage',
  }

  const labels = {
    studyLevel: 'Study level',
    selectionRank: 'Lowest selection rank',
    location: 'Location',
    intake: 'Intake',
    status: 'Status',
    value: 'Value',
    applicationsOpen: 'Applications open',
    applicationsClose: 'Applications close',
    duration: 'Duration',
    readMoreAbout: 'Read more about',
    this: 'this',
    goTo: 'Go to:',
  }

  const iconLabels = {
    calendar: 'calendar_today',
    location: 'location_on',
    price: 'attach_money',
  }

  const columnTypes = {
    string: 'string',
    icon: 'icon',
    tag: 'tag',
  }

  const generateColumn = (
    title: string,
    data: string | string[] | ICodedProps[] | undefined,
    type = columnTypes.string,
    className?: string
  ) => {
    if (data && data.length > 0) {
      return (
        <div className={`result-card__detail ${className ? `result-card__detail--${className}` : ''}`}>
          {/* If type = 'icon', display an icon, otherwise display the title */}
          {title && type === columnTypes.icon ? (
            <i className='result-card__icon' aria-hidden='true'>
              {title}
            </i>
          ) : title && type !== columnTypes.icon ? (
            <Title type='h4' classes='result-card__label'>
              {title}
            </Title>
          ) : null}
          {typeof data === 'object' ? (
            <ul className='result-card__details-list'>
              {data.map((el, index: number) => (
                <li
                  key={`course-table-${uid}-${title}-${
                    el.code || el
                  }-${index}`}>
                  {type === columnTypes.tag ? (
                    <Tag text={el.name} classes={`type--${el.code}`} />
                  ) : (
                    <p>{el}</p>
                  )}
                </li>
              ))}
            </ul>
          ) : type === columnTypes.tag ? (
            <Tag text={data} />
          ) : (
            <p>{data}</p>
          )}
        </div>
      )
    }
    return ''
  }

  /**
   * This finds the accessible anchor of the results cards and applies the link to the whole card, removes the original anchor.
   * @param wrapper HTMLElement The overall container
   */
  const enlargeClicks = () => {
    const classPrefix = 'result-card'
    const cards = document.querySelectorAll(
      `.${classPrefix}`
    ) as NodeListOf<HTMLElement>
    if (cards.length > 0) {
      cards.forEach((card) => {
        const anchor = card.querySelector(
          `.${classPrefix}__link`
        ) as HTMLAnchorElement
        if (anchor) {
          anchor.remove()
          const title =
            card.querySelector(`.${classPrefix}__title`)?.textContent ||
            labels.this
          card.setAttribute('aria-label', `${labels.readMoreAbout} ${title}`)
          card.setAttribute('tabIndex', '0')
          card.style.cursor = 'pointer'
          card.addEventListener('click', () => {
            window.top!.location.href = anchor.href
          })
        }
      })
    }
  }

  useEffect(() => {
    enlargeClicks()
  }, [])

  return (
    <div
      className={classNames('result-card', {
        'result-card--featured': featured,
      })}>
      {image && (
        <div className='result-card__image-wrapper'>
          <Picture {...image} />
        </div>
      )}
      <div className='result-card__content'>
        <div className='result-card__header'>
          {title && <h4 className='result-card__title'>{title}</h4>}
          <Tag text={contentType} />
        </div>
        {contentType === contentTypes.news && duration && (
          <p className='result-card__summary'>{duration[0]}</p>
        )}
        {blurb && <p className='result-card__summary'>{blurb}</p>}
        {positions && (
          <ul className='result-card__summary'>
            {positions.map((el, index: number) => (
              <li key={`course-table-${uid}-positions-${el}-${index}`}>{el}</li>
            ))}
          </ul>
        )}
        {faculties && (
          <ul className='result-card__summary'>
            {faculties.map((el, index: number) => (
              <li key={`course-table-${uid}-faculties-${el}-${index}`}>{el}</li>
            ))}
          </ul>
        )}
        {contentType === contentTypes.course && duration && (
          <ul className='result-card__durations'>
            {duration.map((el, index: number) => (
              <li key={`course-table-${uid}-duration-${el}-${index}`}>{el}</li>
            ))}
          </ul>
        )}
        {[
          contentTypes.course,
          contentTypes.event,
          contentTypes.scholarship,
        ].includes(contentType) && (
          <div
            className={classNames('result-card__details', {
              'result-card__details--event': contentType === contentTypes.event && duration,
              'result-card__details--course': contentType === contentTypes.course
            })}>
            {generateColumn(labels.studyLevel, studyLevels, columnTypes.tag, 'studyLevel')}
            {generateColumn(labels.selectionRank, selectionRanks, columnTypes.string, 'selectionRank')}
            {contentType === contentTypes.course &&
              generateColumn(labels.location, location, columnTypes.string, 'location')}
            {generateColumn(labels.intake, intakes, columnTypes.string, 'intake')}
            {generateColumn(labels.status, status, columnTypes.tag)}
            {contentType === contentTypes.scholarship &&
              generateColumn(labels.value, price, columnTypes.string)}
            {contentType === contentTypes.scholarship &&
              generateColumn(labels.applicationsOpen, applications?.open, columnTypes.string)}
            {contentType === contentTypes.scholarship &&
              generateColumn(labels.applicationsClose, applications?.close, columnTypes.string)}
            {contentType === contentTypes.scholarship &&
              generateColumn(labels.duration, duration, columnTypes.string)}
            {contentType === contentTypes.event &&
              generateColumn(iconLabels.calendar, duration, columnTypes.icon)}
            {contentType === contentTypes.event &&
              generateColumn(iconLabels.location, location, columnTypes.icon)}
            {contentType === contentTypes.event &&
              generateColumn(iconLabels.price, price, columnTypes.icon)}
          </div>
        )}
        <a href={url} className={classNames('result-card__link', 'sr-only')}>
          Go to: {url}
        </a>
      </div>
    </div>
  )
}

export default ResultCard
