import { ReactNode } from 'react'

interface IFilterItem {
  count: number
  name: string
  value: string
  selected: boolean
  unSelectAll: boolean
}
interface ISortItem {
  sortType: string
  name: string
  value: string
  selected: boolean
}

export interface IClearFlag {
  filter: string
  status: boolean
}
export default interface ISearchFiltersProps {
  facets: {
    name: string
    value: string
    filters: IFilterItem[]
  }[]
  openFacets: string[] | undefined
  openFacetsCallBack: Function
  sortItems?: ISortItem[]
  buttonLabel: string
  clearAll?: boolean
  callBack: Function
  clearFlag?: IClearFlag | undefined
}

export interface IBlock {
  body: ReactNode
  expanded: boolean
  headline: string
  id: string
}

export interface IAccordionProps {
  blocks: IBlock[]
}

export enum ECheckboxTypes {
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
}
export interface ICheckboxProps {
  name: string
  value: string
  sortType?: string
  unSelectAll: boolean
  type: ECheckboxTypes
  required?: boolean
  disabled?: boolean
  group: string
  tabIndex: number
}
