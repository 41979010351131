import React from 'react'

import IProps from './interface'

const ResultsCount = (props: IProps) => {
  const { totalItems, pageSize, currentPage, contentType } = props

  // Because on "Find a course" (displaying only `contentType=course`) we "load more", startIndex is always 1. BA 2024-08-13
  const startIndex =
    contentType === 'course' ? 1 : (currentPage - 1) * pageSize + 1
  const endIndex =
    pageSize * currentPage >= totalItems ? totalItems : pageSize * currentPage

  return (
    <div className='resultsCount' aria-labelledby='results-count'>
      <p id='results-count'>
        {totalItems > 0 ? (
          <>
            <span className='resultsCount__bold'>
              {startIndex.toLocaleString()} - {endIndex.toLocaleString()}
            </span>{' '}
            of{' '}
            <span className='resultsCount__bold'>
              {totalItems.toLocaleString()}
            </span>{' '}
            {contentType} results
          </>
        ) : (
          <>
            Your search returned{' '}
            <span className='resultsCount__bold'>{totalItems} results</span>
          </>
        )}
      </p>
    </div>
  )
}

export default ResultsCount
