import React from 'react'

import IProps from './interface'

const QuickFilterButton = (props: IProps) => {
  const { text, callBack = () => {} } = props

  return (
    <button
      aria-label={`Clear filter: ${text}`}
      className='button button__type--secondary button__size--base quickFilterButton'
      onClick={callBack}>
      {text}
      <i className='icon__sm' aria-hidden='true'>
        close
      </i>
    </button>
  )
}

export default QuickFilterButton
