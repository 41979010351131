import React, { useState, useEffect, Fragment } from 'react'

import {
  IResultItem,
  ISearchResponse,
  ISearchBody,
  ISortItem,
  IFacet,
  IFacetFilter,
} from '../../Utils/interface'
import {
  defaults,
  END_POINTS,
  dataFetcher,
  validateData,
  controlZIndexes,
} from '../../Utils/utils'
import IProps from './interface'
import BlockWrapper from '../../../Layout/BlockWrapper/BlockWrapper'
import CourseHeader from '../../Element/CourseHeader/CourseHeader'
import FiltersMenu from '../../Element/FiltersMenu/FiltersMenu'
import CustomSelect from '../../Element/CustomSelect/CustomSelect'
import Loading from '../../Element/Loading/Loading'
import ResultsCount from '../../Element/ResultsCount/ResultsCount'
import ResultCard from '../../Element/ResultCard/ResultCard'
import QuickFilterButton from '../../Element/QuickFilterButton/QuickFilterButton'
import { IClearFlag } from '../../Element/FiltersMenu/interface'

const FindACourse = (props: IProps) => {
  const {
    pageId = '',
    baseURL = '',
    title = '',
    introText = '',
    quickLinks,
    backgroundImage,
    ...rest
  } = props

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [loadMore, setLoadMore] = useState(false)
  const [pagination, setPagination] = useState<
    ISearchResponse['pagination'] | undefined
  >()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [results, setResults] = useState<IResultItem[] | undefined>()
  const [currentSearch, setCurrentSearch] = useState<ISearchBody | undefined>()
  const [sortOptions, setSortOptions] = useState<ISortItem[] | undefined>()
  const [facets, setFacets] = useState<IFacet[] | undefined>()
  const [clearFlag, setClearFlag] = useState<IClearFlag>()
  const [selectedFacets, setSelectedFacets] = useState<
    IFacet['value'][] | undefined
  >()
  const [quickFilters, setQuickFilters] = useState<IFacetFilter[] | undefined>()
  const [previousSearch, setPreviousSearch] = useState<
    ISearchBody | undefined
  >()
  const [isLastPage, setIsLastPage] = useState(false)
  // This will help maintain filters menu accordion items open/close while interacting with filters.
  const [openFacets, setOpenFacets] = useState<string[] | undefined>()

  const defaultSearch: ISearchBody = {
    pageId,
    Group: defaults.groupCourses,
    Page: 1,
    pageSize: defaults.pageSize,
    sortType: '',
    sortValue: '',
  }

  /**
   * This actually sends the search request and populates the variables with the response.
   * @param searchBody object
   */
  const proceedToSearch = async (searchBody: ISearchBody) => {
    if (
      !previousSearch ||
      JSON.stringify(previousSearch) !== JSON.stringify(searchBody)
    ) {
      // This test prevents request loop when no change is actively made
      setPreviousSearch(searchBody)
      if (!loadMore) {
        setLoading(true)
        setResults(undefined)
      }
      const response: ISearchResponse = await dataFetcher(
        `${baseURL}${END_POINTS.search}`,
        searchBody
      )
      if (response?.results) {
        const validData = validateData(response)
        if (validData.log.length > 0) {
          console.log(validData.log)
        }

        setError(false)

        if (loadMore) {
          setResults((results) => {
            setLoadMore(false)
            results?.push(...response.results)
            return results
          })
        } else {
          setResults(response.results)
        }
        setFacets(response.categorisation.facets)
        setSortOptions(response.categorisation.sortItems)
        setCurrentPage(response.pagination.currentPage)
        setPagination(response.pagination)
        setIsLastPage(
          (response.pagination?.currentPage || 1) >=
            (response.pagination?.totalPages || 1)
        )
        setLoading(false)
      } else {
        setError(true)
        setLoading(false)
      }
    }
  }

  /**
   * Creates new body request with new facets
   * @param facets string[]
   */
  const facetsCallback = (facets: string[]) => {
    if (currentSearch) {
      const body: ISearchBody = {
        ...currentSearch,
        Page: defaultSearch.Page,
        Filters: facets,
      }
      setSelectedFacets(facets)
      setCurrentSearch(body)
    }
  }

  /**
   * Creates new body request with new sorting options
   * @param props object
   */
  const sortCallback = (props: { sortType: string; sortValue: string }) => {
    if (currentSearch) {
      const { sortType, sortValue } = props
      const body = {
        ...currentSearch,
        Page: defaultSearch.Page,
        sortType,
        sortValue,
      }
      setCurrentSearch(body)
    }
  }

  /**
   * Creates new body request with new page number
   * @param Page number
   */
  const loadMoreCallback = (Page: number) => {
    if (currentSearch) {
      const body = {
        ...currentSearch,
        Page,
      }
      setLoadMore(true)
      setCurrentSearch(body)
    }
  }

  /**
   * Creates new body request with new search term from the search input
   * @param Term string
   */
  const submitCallback = (Term: string) => {
    const body = {
      ...defaultSearch,
      ...currentSearch,
      Page: defaultSearch.Page,
      Term,
    }
    setCurrentSearch(body)
  }

  /**
   * Sends message to remove the provided "Quick filter"
   * @param value string
   */
  const quickFiltersCallback = (value: string) => {
    setClearFlag({
      filter: value,
      status: true,
    })
    setTimeout(() => setClearFlag(defaults.clearFlag), 200)
  }

  /**
   * Each time the current search body is updated by one of the previous callback functions,
   *  - check that the search term is still up to date,
   *  - then proceed to search with the body.
   */
  useEffect(() => {
    if (currentSearch) {
      const searchTerm = (document.querySelector('#search') as HTMLInputElement)
        ?.value
      // Just checking if the search input hasn't been cleared
      let body = {
        ...currentSearch,
      }
      if (searchTerm === '') {
        body = {
          ...body,
          Term: '',
        }
      }
      if (JSON.stringify(previousSearch) !== JSON.stringify(body)) {
        // This test prevents request loop when no change is actively made
        proceedToSearch(body)
      }
    }
  }, [currentSearch])

  /**
   * This updates the list of Quickfilters depending on the filters selected in the Filters menu
   */
  useEffect(() => {
    const selection = facets?.map((facet) => {
      return facet.filters.filter((filter) =>
        selectedFacets?.includes(filter.value)
      )
    })

    setQuickFilters(selection?.flat())
  }, [selectedFacets])

  useEffect(() => {
    controlZIndexes()
  }, [])

  return (
    <BlockWrapper {...rest}>
      <div>
        <CourseHeader
          baseURL={baseURL}
          title={title}
          introText={introText}
          quickLinks={quickLinks}
          backgroundImage={backgroundImage}
          searchTerm={currentSearch ? currentSearch.Term : ''}
          callBack={submitCallback}
        />
        {!loading && sortOptions ? (
          <div className={`findACourse__inner`}
            data-section='Find a course search'
            data-search-group={currentSearch ? currentSearch.Group : ''}
            data-search-term={currentSearch ? currentSearch.Term : ''}
            data-search-filter={currentSearch ? currentSearch.Filters?.toString() : ''}>
            <div className='searchResults__filters-wrapper'>
              {facets ? (
                <FiltersMenu
                  facets={facets}
                  openFacets={openFacets}
                  openFacetsCallBack={setOpenFacets}
                  buttonLabel={'Refine results'}
                  clearAll
                  callBack={facetsCallback}
                  clearFlag={clearFlag}
                />
              ) : null}
              <CustomSelect
                {...defaults.sortOptions}
                options={sortOptions}
                callBack={sortCallback}
              />
              {facets ? (
                <div className='searchResults__quick-filter-wrapper'>
                  {quickFilters?.map(({ name, value }) => (
                    <Fragment key={value}>
                      <QuickFilterButton
                        text={name}
                        callBack={() => quickFiltersCallback(value)}
                      />
                    </Fragment>
                  ))}
                  {quickFilters && quickFilters?.length > 0 ? (
                    <button
                      className='button button__type--tertiary button__size--base searchResults__clear-filters'
                      onClick={(e) => {
                        e.preventDefault()
                        setClearFlag({
                          ...defaults.clearFlag,
                          status: true,
                        })
                        setTimeout(() => setClearFlag(defaults.clearFlag), 500)
                      }}>
                      Clear all <span className='sr-only'>filters</span>
                    </button>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
        <div className='searchResults' aria-live='polite'>
          {loading && !results ? (
            <Loading text='Courses loading...' />
          ) : (
            <>
              {error ? <p>{defaults.error}</p> : null}
              {currentSearch && results && results?.length > 0 ? (
                <>
                  <ResultsCount
                    totalItems={pagination?.totalItems || 0}
                    pageSize={pagination?.pageSize || defaults.pageSize}
                    currentPage={currentPage}
                    contentType='course'
                  />
                  <ul className='searchResults__cards-wrapper'>
                    {results.map((result, index) => {
                      return (
                        <li key={index}>
                          <ResultCard {...result} uid={index.toString()} />
                        </li>
                      )
                    })}
                  </ul>
                  {loadMore && <Loading text='Results loading...' />}
                  {!isLastPage ? (
                    <div className='searchResults__pagination-wrapper'>
                      <button
                        className='button link__color--primary button__type--secondary button__size--base'
                        onClick={() => loadMoreCallback(currentPage + 1)}>
                        Load more
                      </button>
                    </div>
                  ) : null}
                </>
              ) : (
                !error &&
                currentSearch && (
                  <ResultsCount
                    totalItems={pagination?.totalItems || 0}
                    pageSize={pagination?.pageSize || defaults.pageSize}
                    currentPage={currentPage}
                  />
                )
              )}
            </>
          )}
        </div>
      </div>
    </BlockWrapper>
  )
}

export default FindACourse
