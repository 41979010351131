import React from 'react'
import classNames from 'classnames'

import IProps from './interface'

const Tag = (props: IProps) => {
  const { text, classes } = props

  return (
    <span
      className={classNames(
        {
          ['bubble-tag']: true,
        },
        classes || ''
      )}>
      {text}
    </span>
  )
}

export default Tag
